import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { formatPostDate, formatReadingTime } from '../lib/utils/helpers';
import { variables } from '../lib/styles/variables';

const PostItemBlock = styled.article`
  padding: 16px 0;
`;

const Header = styled.header``;

const Title = styled.h3`
  margin: 0 0 8px 0;
`;

const Small = styled.small``;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${variables.primaryColor};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const Description = styled.p``;

interface PostItemProps {
  title: string;
  description: string;
  date: Date;
  slug: string;
  timeToRead: number;
}

const PostItem = ({ title, description, timeToRead, date, slug }: PostItemProps) => {
  return (
    <PostItemBlock>
      <Header>
        <Title>
          <StyledLink to={slug}>{title}</StyledLink>
        </Title>
        <Small>{`${formatPostDate(date)} • ${formatReadingTime(timeToRead)}`}</Small>
      </Header>
      <Description>{description}</Description>
    </PostItemBlock>
  );
};

export default PostItem;
