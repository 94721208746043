import React from 'react';
import styled from 'styled-components';
import { variables } from '../lib/styles/variables';

const IntroBlock = styled.section`
  margin-bottom: 32px;
  padding: 16px 0;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-weight: 900;
`;

const Text = styled.p`
  margin: 0;
`;

const Links = styled.nav`
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  padding-top: 4px;
  margin-top: 8px;
`;

const NavLinks = styled.a`
  text-decoration: none;
  color: ${variables.linkColor};

  & + & {
    margin-left: 16px;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const Intro = () => (
  <IntroBlock>
    <Title>안녕하세요</Title>
    <Text>강영진이라고 합니다. 웹 프론트엔드를 사랑하는 개발자입니다.</Text>
    <Text>프로그래밍과 삶에 대한 생각을 이곳에 기록으로 남기고 있습니다.</Text>
    <Links>
      <NavLinks href="https://github.com/youngjin-k" target="_blank">
        Github
      </NavLinks>
      <NavLinks href="mailto:masichall@gmail.com" target="_blank">
        Email
      </NavLinks>
      <NavLinks href="https://www.buymeacoffee.com/youngjin" target="_blank">
        Buy me a coffee
      </NavLinks>
    </Links>
  </IntroBlock>
);

export default Intro;
