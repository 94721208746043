import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout';
import Intro from '../components/Intro';
import SEO from '../components/SEO';
import PostItem from '../components/PostItem';
import { Query } from '../graphql-types';

const LatestPostListQuery = graphql`
  query LatestPostListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            description
            slug
            date
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery<Query>(LatestPostListQuery);

  return (
    <Layout>
      <SEO title="BLOG" />
      <Intro />

      {data.allMarkdownRemark.edges.map(({ node }) => (
        <PostItem
          key={node.frontmatter.slug}
          title={node.frontmatter.title}
          timeToRead={node.timeToRead}
          description={node.frontmatter.description}
          date={node.frontmatter.date}
          slug={node.frontmatter.slug}
        ></PostItem>
      ))}
    </Layout>
  );
};

export default IndexPage;
